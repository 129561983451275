import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Column from "~components/atoms/column/column";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Image from "~components/atoms/image/image";
import Section from "~components/atoms/section/section";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import RichText from "~components/molecules/rich_text/rich_text";
import usePageData from "~hooks/use_page_data/use_page_data";
import { BLOK_ARRAY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";

const COLUMN_SPAN = { XXL: "8", XS: "12" };

export default function Template404Page(props) {
  const { story, pageContext } = usePageData(props);

  const {
    bodyRichText: [bodyRichText],
    ctaResponsive: [ctaResponsive],
    heading,
    contentImage: [contentImage],
    sectionSettings: [sectionSettings],
    ...rest
  } = story;
  const { subNavData } = pageContext;

  return (
    <TemplateGlobalEntry {...props} headerColor="white" subNavData={subNavData}>
      <Section sectionSettings={sectionSettings} id="404-page" {...rest}>
        <Contain>
          {contentImage && (
            <Column span={COLUMN_SPAN}>
              <Image {...contentImage} />
            </Column>
          )}
          {heading && (
            <Heading visualLevel={1} seoLevel={1} color="white" isCentered>
              {heading}
            </Heading>
          )}
          {bodyRichText && <RichText {...bodyRichText} />}
          {ctaResponsive && <CtaResponsive {...ctaResponsive} />}
        </Contain>
      </Section>
    </TemplateGlobalEntry>
  );
}

Template404Page.propTypes = {
  data: PropTypes.shape({
    story: PropTypes.shape({
      bodyRichText: BLOK_ARRAY,
      contentImage: BLOK_ARRAY,
      ctaResponsive: BLOK_ARRAY,
      heading: PropTypes.string,
      sectionSettings: BLOK_ARRAY,
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Template404Page.defaultProps = {
  pageContext: {
    subNavData: [],
  },
};
// TODO: get page specific metadata here
export const query = graphql`
  query Template404PageQuery($id: String, $langRegex: String) {
    ...story
    ...global
  }
`;
